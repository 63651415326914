import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { SanityDataAccessOptions } from './sanity.config';
import { SanityAccessService } from './sanity.service';

@NgModule({
    providers: [SanityAccessService]
})
export class VbSanityDataAccessModule {
    constructor(@Optional() @SkipSelf() parentModule?: VbSanityDataAccessModule) {
        if (parentModule) {
            throw new Error(
                'VbSanityDataAccessModule is already loaded. Import it in the AppModule only'
            );
        }
    }

    static forRoot(config: SanityDataAccessOptions): ModuleWithProviders<VbSanityDataAccessModule> {
        return {
            ngModule: VbSanityDataAccessModule,
            providers: [{ provide: SanityDataAccessOptions, useValue: config }]
        };
    }
}
